import React from "react";
import * as Icon from "react-feather";
import { getUser } from "utility/Common";
const navigationConfigDireksi = [
  {
    type: "groupHeader",
    groupTitle: "Dashboard Manajemen",
  },
  {
    id: "capaian_target",
    title: "Capaian Target",
    type: "item",
    icon: <Icon.Target size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/target_omset",
  },
  {
    id: "trend_omset",
    title: "Trend Omset",
    type: "item",
    icon: <Icon.TrendingUp size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/trend_omset",
  },
  {
    id: "omset_per_perusahaan",
    title: "Omset Per Customer",
    type: "item",
    icon: <Icon.Trello size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/omset_per_cust",
  },
  {
    id: "omset_per_unit",
    title: "Omset Per Unit",
    type: "item",
    icon: <Icon.Grid size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/omset_per_unit",
  },
  {
    id: "kunj_per_perusahaan",
    title: "Kunj. Per Customer",
    type: "item",
    icon: <Icon.Layers size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/kunj_per_cust",
  },
  {
    id: "kunj_per_unit",
    title: "Kunj. Per Unit",
    type: "item",
    icon: <Icon.FileText size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/kunj_per_unit",
  },
  {
    id: "Morbiditas",
    title: "Morbiditas",
    type: "item",
    icon: <Icon.Columns size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/Morbiditas",
  },
  {
    id: "demografi_per_usia",
    title: "Demografi",
    type: "item",
    icon: <Icon.Loader size={13} />,
    permissions: ["TI","DIREKSI", "MANAJER"],
    navLink: "/dashboard/demografi",
  },
  // {
  //   id: "Bor",
  //   title: "Bor",
  //   type: "item",
  //   icon: <Icon.Sidebar size={13} />,
  //   permissions: ["TI","DIREKSI", "MANAJER"],
  //   navLink: "/dashboard/bor",
  // },

];

export default navigationConfigDireksi;
